import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, PLATFORM_ID, Directive, Input, Output, Self, Component, ChangeDetectionStrategy, ViewChild, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { from, of, animationFrameScheduler, Subject, BehaviorSubject, defer, Observable } from 'rxjs';
import { map, tap, shareReplay, observeOn, filter, switchMap, takeUntil } from 'rxjs/operators';
import * as i1 from '@angular/platform-browser';
import { makeStateKey } from '@angular/platform-browser';
const _c0 = ["container"];
const LOTTIE_OPTIONS = new InjectionToken('LottieOptions');
function convertPlayerOrLoaderToObservable(player, useWebWorker) {
  const playerOrLoader = player();
  const player$ = playerOrLoader instanceof Promise ? from(playerOrLoader).pipe(map(module => module.default || module)) : of(playerOrLoader);
  return player$.pipe(tap(player => player.useWebWorker(useWebWorker)), shareReplay({
    bufferSize: 1,
    refCount: true
  }));
}
class AnimationLoader {
  constructor(ngZone, options) {
    this.ngZone = ngZone;
    this.options = options;
    this.player$ = convertPlayerOrLoaderToObservable(this.options.player, this.options.useWebWorker).pipe(observeOn(animationFrameScheduler));
  }
  loadAnimation(options) {
    return this.player$.pipe(map(player => this.createAnimationItem(player, options)));
  }
  resolveOptions(options, container) {
    return Object.assign({
      container,
      renderer: 'svg',
      loop: true,
      autoplay: true
    }, options);
  }
  createAnimationItem(player, options) {
    return this.ngZone.runOutsideAngular(() => player.loadAnimation(options));
  }
}
/** @nocollapse */
AnimationLoader.ɵfac = function AnimationLoader_Factory(t) {
  return new (t || AnimationLoader)(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(LOTTIE_OPTIONS));
};
/** @nocollapse */
AnimationLoader.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: AnimationLoader,
  factory: AnimationLoader.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AnimationLoader, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [LOTTIE_OPTIONS]
      }]
    }];
  }, null);
})();
class BaseDirective {
  constructor(ngZone, platformId, animationLoader) {
    this.ngZone = ngZone;
    this.platformId = platformId;
    this.animationLoader = animationLoader;
    this.options = null;
    this.containerClass = null;
    this.styles = null;
    /**
     * `animationCreated` is dispatched after calling `loadAnimation`.
     */
    this.animationCreated = this.getAnimationItem();
    /**
     * `complete` is dispatched after completing the last frame.
     */
    this.complete = this.awaitAnimationItemAndStartListening('complete');
    /**
     * `loopComplete` is dispatched after completing the frame loop.
     */
    this.loopComplete = this.awaitAnimationItemAndStartListening('loopComplete');
    /**
     * `enterFrame` is dispatched after entering the new frame.
     */
    this.enterFrame = this.awaitAnimationItemAndStartListening('enterFrame');
    /**
     * `segmentStart` is dispatched when the new segment is adjusted.
     */
    this.segmentStart = this.awaitAnimationItemAndStartListening('segmentStart');
    /**
     * Original event name is `config_ready`. `config_ready` is dispatched
     * after the needed renderer is configured.
     */
    this.configReady = this.awaitAnimationItemAndStartListening('config_ready');
    /**
     * Original event name is `data_ready`. `data_ready` is dispatched
     * when all parts of the animation have been loaded.
     */
    this.dataReady = this.awaitAnimationItemAndStartListening('data_ready');
    /**
     * Original event name is `DOMLoaded`. `DOMLoaded` is dispatched
     * when elements have been added to the DOM.
     */
    this.domLoaded = this.awaitAnimationItemAndStartListening('DOMLoaded');
    /**
     * `destroy` will be dispatched when the component gets destroyed,
     * it's handy for releasing resources.
     */
    this.destroy = this.awaitAnimationItemAndStartListening('destroy');
    /**
     * `error` will be dispatched if the Lottie player could not render
     * some frame or parse config.
     */
    this.error = this.awaitAnimationItemAndStartListening('error');
    this.destroy$ = new Subject();
    this.loadAnimation$ = new Subject();
    this.animationItem$ = new BehaviorSubject(null);
    this.setupLoadAnimationListener();
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroyAnimation();
  }
  loadAnimation(changes, container) {
    // The `loadAnimation` may load `lottie-web` asynchronously and also pipes the player
    // with `animationFrameScheduler`, which schedules an animation task and triggers change
    // detection. We'll trigger change detection only once when the animation item is created.
    this.ngZone.runOutsideAngular(() => this.loadAnimation$.next([changes, container]));
  }
  getAnimationItem() {
    return defer(() => this.animationItem$).pipe(filter(animationItem => animationItem !== null));
  }
  awaitAnimationItemAndStartListening(name) {
    return this.getAnimationItem().pipe(switchMap(animationItem =>
    // `fromEvent` will try to call `removeEventListener` when `unsubscribe()` is invoked.
    // The problem is that `ngOnDestroy()` is called before Angular unsubscribes from
    // `@Output()` properties, thus `animationItem` will be `null` already, also `lottie-web`
    // removes event listeners when calling `destroy()`.
    new Observable(observer => {
      this.ngZone.runOutsideAngular(() => {
        animationItem.addEventListener(name, event => {
          this.ngZone.runOutsideAngular(() => {
            observer.next(event);
          });
        });
      });
    })));
  }
  setupLoadAnimationListener() {
    const loadAnimation$ = this.loadAnimation$.pipe(filter(([changes]) => isPlatformBrowser(this.platformId) && changes.options !== undefined));
    loadAnimation$.pipe(switchMap(([changes, container]) => {
      this.destroyAnimation();
      return this.animationLoader.loadAnimation(this.animationLoader.resolveOptions(changes.options.currentValue, container));
    }), takeUntil(this.destroy$)).subscribe(animationItem => {
      this.ngZone.run(() => this.animationItem$.next(animationItem));
    });
  }
  destroyAnimation() {
    const animationItem = this.animationItem$.getValue();
    // The `ng-lottie` component or the `lottie` directive can be destroyed
    // before the `animationItem` is set, thus it will fail with
    // `Cannot read property 'destroy' of null`.
    // Potentially it can happen if the directive gets destroyed before change
    // detection is run.
    if (animationItem === null) {
      return;
    }
    // `destroy()` will remove all events listeners.
    animationItem.destroy();
    this.animationItem$.next(null);
  }
}
/** @nocollapse */
BaseDirective.ɵfac = function BaseDirective_Factory(t) {
  return new (t || BaseDirective)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(AnimationLoader));
};
/** @nocollapse */
BaseDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: BaseDirective,
  selectors: [["", "lottie", ""]],
  inputs: {
    options: "options",
    containerClass: "containerClass",
    styles: "styles"
  },
  outputs: {
    animationCreated: "animationCreated",
    complete: "complete",
    loopComplete: "loopComplete",
    enterFrame: "enterFrame",
    segmentStart: "segmentStart",
    configReady: "configReady",
    dataReady: "dataReady",
    domLoaded: "domLoaded",
    destroy: "destroy",
    error: "error"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseDirective, [{
    type: Directive,
    args: [{
      selector: '[lottie]'
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }, {
      type: AnimationLoader
    }];
  }, {
    options: [{
      type: Input
    }],
    containerClass: [{
      type: Input
    }],
    styles: [{
      type: Input
    }],
    animationCreated: [{
      type: Output
    }],
    complete: [{
      type: Output
    }],
    loopComplete: [{
      type: Output
    }],
    enterFrame: [{
      type: Output
    }],
    segmentStart: [{
      type: Output
    }],
    configReady: [{
      type: Output
    }],
    dataReady: [{
      type: Output
    }],
    domLoaded: [{
      type: Output
    }],
    destroy: [{
      type: Output
    }],
    error: [{
      type: Output
    }]
  });
})();
class LottieDirective extends BaseDirective {
  constructor(ngZone, platformId, host, animationLoader) {
    super(ngZone, platformId, animationLoader);
    this.host = host;
  }
  ngOnChanges(changes) {
    super.loadAnimation(changes, this.host.nativeElement);
  }
}
/** @nocollapse */
LottieDirective.ɵfac = function LottieDirective_Factory(t) {
  return new (t || LottieDirective)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(i0.ElementRef, 2), i0.ɵɵdirectiveInject(AnimationLoader));
};
/** @nocollapse */
LottieDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: LottieDirective,
  selectors: [["", "lottie", ""]],
  standalone: true,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LottieDirective, [{
    type: Directive,
    args: [{
      selector: '[lottie]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Self
      }]
    }, {
      type: AnimationLoader
    }];
  }, null);
})();
class LottieComponent extends BaseDirective {
  constructor(ngZone, platformId, animationLoader) {
    super(ngZone, platformId, animationLoader);
    this.width = null;
    this.height = null;
    this.container = null;
  }
  ngOnChanges(changes) {
    super.loadAnimation(changes, this.container.nativeElement);
  }
}
/** @nocollapse */
LottieComponent.ɵfac = function LottieComponent_Factory(t) {
  return new (t || LottieComponent)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(AnimationLoader));
};
/** @nocollapse */
LottieComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: LottieComponent,
  selectors: [["ng-lottie"]],
  viewQuery: function LottieComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.container = _t.first);
    }
  },
  inputs: {
    width: "width",
    height: "height"
  },
  standalone: true,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
  decls: 2,
  vars: 6,
  consts: [["container", ""], [3, "ngStyle", "ngClass"]],
  template: function LottieComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "div", 1, 0);
    }
    if (rf & 2) {
      i0.ɵɵstyleProp("width", ctx.width || "100%")("height", ctx.height || "100%");
      i0.ɵɵproperty("ngStyle", ctx.styles)("ngClass", ctx.containerClass);
    }
  },
  dependencies: [CommonModule, i2.NgClass, i2.NgStyle],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LottieComponent, [{
    type: Component,
    args: [{
      selector: 'ng-lottie',
      template: `
    <div
      #container
      [style.width]="width || '100%'"
      [style.height]="height || '100%'"
      [ngStyle]="styles"
      [ngClass]="containerClass"
    ></div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true,
      imports: [CommonModule]
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }, {
      type: AnimationLoader
    }];
  }, {
    width: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    container: [{
      type: ViewChild,
      args: ['container', {
        static: true
      }]
    }]
  });
})();
class LottieModule {
  static forRoot(options) {
    return {
      ngModule: LottieModule,
      providers: [{
        provide: LOTTIE_OPTIONS,
        useValue: options
      }]
    };
  }
}
/** @nocollapse */
LottieModule.ɵfac = function LottieModule_Factory(t) {
  return new (t || LottieModule)();
};
/** @nocollapse */
LottieModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: LottieModule
});
/** @nocollapse */
LottieModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [LottieComponent]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LottieModule, [{
    type: NgModule,
    args: [{
      imports: [LottieDirective, LottieComponent],
      exports: [LottieDirective, LottieComponent]
    }]
  }], null, null);
})();
class CacheableAnimationLoader extends AnimationLoader {
  constructor() {
    super(...arguments);
    this.cache = new Map();
  }
  ngOnDestroy() {
    this.cache.clear();
  }
  loadAnimation(options) {
    return this.player$.pipe(map(player => {
      const animationItem = this.createAnimationItem(player, this.transformOptions(options));
      this.awaitConfigAndCache(options, animationItem);
      return animationItem;
    }));
  }
  awaitConfigAndCache(options, animationItem) {
    if (this.isAnimationConfigWithPath(options)) {
      // Don't wait for the `config_ready` event if it has been cached previously.
      if (this.cache.has(options.path)) {
        return;
      }
      animationItem.addEventListener('config_ready', () => {
        // See the comments below on why we're storing the animation data as a string.
        this.cache.set(options.path, JSON.stringify(animationItem['animationData']));
      });
    }
  }
  transformOptions(options) {
    if (this.isAnimationConfigWithPath(options) && this.cache.has(options.path)) {
      return {
        ...options,
        path: undefined,
        // Caretaker note: `lottie-web` cannot re-use the `animationData` object between animations, and we
        // have to retrieve a new object each time an animation is created.
        // https://github.com/airbnb/lottie-web#html
        // See comments for the `animationData` property.
        animationData: JSON.parse(this.cache.get(options.path))
      };
    } else {
      return options;
    }
  }
  isAnimationConfigWithPath(options) {
    return typeof options.path === 'string';
  }
}
/** @nocollapse */
CacheableAnimationLoader.ɵfac = /* @__PURE__ */(() => {
  let ɵCacheableAnimationLoader_BaseFactory;
  return function CacheableAnimationLoader_Factory(t) {
    return (ɵCacheableAnimationLoader_BaseFactory || (ɵCacheableAnimationLoader_BaseFactory = i0.ɵɵgetInheritedFactory(CacheableAnimationLoader)))(t || CacheableAnimationLoader);
  };
})();
/** @nocollapse */
CacheableAnimationLoader.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: CacheableAnimationLoader,
  factory: CacheableAnimationLoader.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CacheableAnimationLoader, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class LottieCacheModule {
  static forRoot() {
    return {
      ngModule: LottieCacheModule,
      providers: [{
        provide: AnimationLoader,
        useExisting: CacheableAnimationLoader
      }]
    };
  }
}
/** @nocollapse */
LottieCacheModule.ɵfac = function LottieCacheModule_Factory(t) {
  return new (t || LottieCacheModule)();
};
/** @nocollapse */
LottieCacheModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: LottieCacheModule
});
/** @nocollapse */
LottieCacheModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LottieCacheModule, [{
    type: NgModule
  }], null, null);
})();
function provideCacheableAnimationLoader() {
  return [{
    provide: AnimationLoader,
    useExisting: CacheableAnimationLoader
  }];
}
function provideLottieOptions(options) {
  return [{
    provide: LOTTIE_OPTIONS,
    useValue: options
  }];
}
function transformAnimationFilenameToKey(animation) {
  const [animationName] = animation.split('.json');
  return `animation-${animationName}`;
}
class LottieTransferState {
  constructor(transferState) {
    this.transferState = transferState;
  }
  get(animation) {
    const animationKey = transformAnimationFilenameToKey(animation);
    const stateKey = makeStateKey(animationKey);
    return this.transferState.get(stateKey, null);
  }
}
/** @nocollapse */
LottieTransferState.ɵfac = function LottieTransferState_Factory(t) {
  return new (t || LottieTransferState)(i0.ɵɵinject(i1.TransferState));
};
/** @nocollapse */
LottieTransferState.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: LottieTransferState,
  factory: LottieTransferState.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LottieTransferState, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i1.TransferState
    }];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AnimationLoader, BaseDirective, LottieCacheModule, LottieComponent, LottieDirective, LottieModule, LottieTransferState, provideCacheableAnimationLoader, provideLottieOptions, transformAnimationFilenameToKey, CacheableAnimationLoader as ɵCacheableAnimationLoader, LOTTIE_OPTIONS as ɵLOTTIE_OPTIONS };

